const config = {
  googleplay: 'https://cashpaddiedeeplink.onelink.me/nxwX/rgf9hs9e',
  apk: 'https://www.cashpaddie.com/download/Cashpaddie.apk',
  api: {
    deleteAccount: {
      CHECK_STATUS: '/h5/deleteInfo/checkStatus',
      SEND_SMS_CODE: '/h5/customer/sendSmsCode',
      LOGIN: '/h5/customer/login',
      APPLY_DELETE_INFO: '/h5/deleteInfo/applyDeleteInfo',
      TERMINATION_DELETE: '/h5/deleteInfo/terminationDelete',
      APP_CONFIG: '/h5/app-configuration-value',
      PROTOCOL_CONFIG: '/api/h5/contract/view-html',
    }
  }
};

export default config