import React, { useState, useEffect } from 'react';
import AgreementTitle from './AgreementTitle';
import AgreementWrap from './AgreementWrap';
import './PrivacyPolicy.css';
import './View.css';
import { createEnv } from '../../server-config';
import config from '../../config';
import httpClient from '../../http-client';
const PrivacyPolicy: React.FC = () => {
  const clientId = createEnv().clientId;
  const api = config.api.deleteAccount;
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          clientId,
          templateCode: 'Cashpaddie_privacy_policy'
        };
        const response:any = await httpClient.post(api.PROTOCOL_CONFIG, params);
        setData(response.result.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [clientId, api.PROTOCOL_CONFIG]);
  return (
    <AgreementWrap>
      <div className="container">
        <AgreementTitle title="Privacy Policy" />
        {data && <div dangerouslySetInnerHTML={{ __html: data }} />}
      </div>
    </AgreementWrap>
  );
};

export default PrivacyPolicy;
