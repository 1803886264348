import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import DataContext from './DataContext';
import httpClient from './http-client';
import config from './config';
import { createEnv } from './server-config';
// Components
import Home from './components/Home/Home';
import PrivacyPolicy from './components/Agreement/PrivacyPolicy';
import TermsOfUse from './components/Agreement/TermsOfUse';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Space from './components/Space';

// Delete Account Components
import Application from './delete-account/Application/Application';
import Cancel from './delete-account/Cancel/Cancel';
import Doing from './delete-account/Doing/Doing';
import Done from './delete-account/Done/Done';
import ErrorAgain from './delete-account/ErrorAgain/ErrorAgain';
import ErrorOrder from './delete-account/ErrorOrder/ErrorOrder';

interface LayoutProps {
  children?: JSX.Element;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
  return <main>{children}</main>;
};

const App: React.FC = () => {
  const location = useLocation();
  const clientId = createEnv().clientId;
  const api = config.api.deleteAccount;
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          clientId,
          configKey: ["APP_CUSTOMER_SERVICE"]
        };
        const response:any = await httpClient.post(api.APP_CONFIG, params);
        setData(JSON.parse(response.result.APP_CUSTOMER_SERVICE));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [clientId, api.APP_CONFIG]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const pathname = location.pathname && location.pathname.split('/')[1];
    document.body.className = pathname;
  }, [location.pathname]);
  return (
    <div className="App">
      <DataContext.Provider value={{ data }}>
        <Navbar />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/delete-account" element={<Application />} />
            <Route path="/delete-account/cancel" element={<Cancel />} />
            <Route path="/delete-account/doing" element={<Doing />} />
            <Route path="/delete-account/done" element={<Done />} />
            <Route path="/delete-account/error-again" element={<ErrorAgain />} />
            <Route path="/delete-account/error-order" element={<ErrorOrder />} />
          </Routes>
        </Layout>
        <Space />
        <Footer />
      </DataContext.Provider>
    </div>
  );
};

export default App;
